import React, { useState, useRef, useEffect } from 'react';
import Turntable from './Turntable';
import './Djpanel.css';
import music from './assetts/pitbull_comp.mp3'

function DjPanel() {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef();
    const canvasRef = useRef();
    const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
    const sourceRef = useRef();
    const analyserRef = useRef(audioContextRef.current.createAnalyser());
  
    useEffect(() => {
      sourceRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);
      sourceRef.current.connect(analyserRef.current);
      analyserRef.current.connect(audioContextRef.current.destination);
    }, []);
  
    useEffect(() => {
      if (!isPlaying) return;
  
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
  
      const drawWaveform = () => {
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
        analyserRef.current.getByteTimeDomainData(dataArray);
  
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
  
        const sliceWidth = canvas.width / analyserRef.current.frequencyBinCount;
        let x = 0;
  
        for (let i = 0; i < analyserRef.current.frequencyBinCount; i++) {
          const v = dataArray[i] / 128.0;
          const y = v * canvas.height / 2;
  
          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
  
          x += sliceWidth;
        }
  
        ctx.strokeStyle = 'lime';
        ctx.lineWidth = 2;
        ctx.stroke();
  
        requestAnimationFrame(drawWaveform);
      };
  
      drawWaveform();
    }, [isPlaying]);
  
    const handlePlayPause = async () => {
        if (audioContextRef.current.state === 'suspended') {
          await audioContextRef.current.resume();
        }
      
        const audio = audioRef.current;
        if (audio.paused) {
          setIsPlaying(true);
          audio.play();
        } else {
          setIsPlaying(false);
          audio.pause();
        }
      };
      
      
      
  
    return (
      <div className="djpanel-container">
              <button onClick={handlePlayPause}>Play/Pause</button> {/* Play/Pause button */}
        <div className="turntables">
          <Turntable />
          <canvas ref={canvasRef} className="waveform" />
          <Turntable />
        </div>
      <div className="controls">
        <div className="buttons">
          {Array.from({ length: 40 }, (_, i) => (
            <button key={i} className="control-button">B{i + 1}</button>
          ))}
        </div>
        <div className="knobs">
          {Array.from({ length: 30 }, (_, i) => (
            <input key={i} type="range" min="0" max="100" className="knob" />
          ))}
        </div>
        <div className="sliders">
          {Array.from({ length: 10 }, (_, i) => (
            <input key={i} type="range" min="0" max="100" className="slider" />
          ))}
        </div>
      </div>
      <div className="knobs">
            {Array.from({ length: 40 }, (_, i) => (
                <div key={i} className="knob-container">
                <div className="knob-indicator" />
                <div className="labels">Knob {i + 1}</div>
                </div>
            ))}
            </div>

        <div className="sliders">
          {Array.from({ length: 20 }, (_, i) => (
            <div key={i}>
              <input type="range" min="0" max="100" className="slider" />
              <div className="labels">Slider {i + 1}</div>
            </div>
          ))}
        </div>
        <audio ref={audioRef} src={music} autoPlay loop /> {/* Update the path to your audio file */}

      </div>
  );
}

export default DjPanel;
