import React, { useState, useEffect } from 'react';
import './Turntable.css';

function Turntable() {
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(true);

  useEffect(() => {
    const spinInterval = setInterval(() => {
      if (isSpinning) {
        setRotation((prevRotation) => {
          const newRotation = prevRotation + 5;
          return newRotation;
        });
      }
    }, 100);
    return () => clearInterval(spinInterval);
  }, [isSpinning]);


  const handleSpin = () => {
    setIsSpinning(!isSpinning); // Toggle spinning state

    if (!isSpinning) { // If it was not spinning before the click, reset rotation to default
      setRotation(0);
    }
  };

  return (
    <div className="turntable-container" onClick={handleSpin}>
      <div className="turntable">
        <div className="rim"></div>
        <div
          className="record"
          style={{ transform: `rotate(${rotation}deg)` }}
        >
          <div className="label"></div>
        </div>
        <div className="needle-arm"></div>
      </div>
    </div>
  );
}

export default Turntable;
