import { React, useState } from 'react';
import styles from './Website.css';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import DjPanel from './Djpanel';
import bookCover from './assetts/Cover.jpg';
import ContactForm from './ContactForm';
import bookPdf from './assetts/Histories Greatest Pusshounds.pdf';
import djfracking from './assetts/DJ4.png'

const LandingPage = () => {
    const [selectedPayer, setSelectedPayer] = useState(null);

    const handlePayerSelect = (payer) => {
      setSelectedPayer(payer);
    };

    return (
        <div className="landing-page">
            <Helmet>
                <title>DJFracking</title>
                <meta name="description" content="The ultimate enigma" />
                <meta name="keywords" content="DJFracking, DJ" />
                <meta property="og:title" content="DJFracking" />
                <meta property="og:description" content="The ultimate enigma" />
            </Helmet>
            <div className='logo-container'>
                <div className="logo-bg"></div>
            </div>
            <div className="main-content">
                <main>
                    <div className="block">
                        <DjPanel />
                    </div>

                    <section>
                    <h2>DJFracking's Favorite Songs</h2>
                    <div className="underline"></div>
                    <div className="spotify-playlist">
                <iframe 
                    style={{ borderRadius: '12px' }}
                    src="https://open.spotify.com/embed/playlist/0ioBuRyZIsCAXCR2jmOuDo?utm_source=generator&theme=0"
                    width="100%" 
                    height="452" 
                    frameBorder="0" 
                    allowfullscreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy"
                    title="Spotify Playlist"
                ></iframe>

            </div>

                </section>
                <section className="book-section">
                    <h2>That Time DJFracking Wrote a Book</h2>
                    <div className="underline"></div>
                    <a href={bookPdf} download>
                        <img className="bookImage" src={bookCover} alt="DJFracking's Book Cover" />
                    </a>
                    <p>Click on the book cover to download the PDF.</p>
                </section>
                <section className="services-section">
                <h2>Services</h2>                    
                <div className="underline"></div>
                <ul className="services-list">
                    <li className="service-item"><span>DJ</span></li>
                    <li className="service-item"><span>Public Speaking</span></li>
                    <li className="service-item"><span>Verizon Certified Internet Installation</span></li>
                    <li className="service-item"><span>Music Production</span></li>  {/* Added as an example */}
                    <li className="service-item"><span>Event Management</span></li>  {/* Added as an example */}
                    <li className="service-item"><span>Nuclear Disaster Relief</span></li>  {/* Added as an example */}

                </ul>
            </section>
            <section className="about-section">
            <div className="left-div">
            <div className="about-image">
                <img src={djfracking} alt="DJFracking" />
            </div>
            </div>
            <div className="right-div">
                <div className="about-content">
                    <p>
                DJ Fracking is a man of enigma, whose true identity remains veiled behind the sonic landscapes
                he creates and the vibrant atmospheres he conjures on the stage. Unseen, yet profoundly felt, DJ
                Fracking is a spirit that speaks to the soul through music, an elusive enchanter who defies the
                conventional confines of a mundane life.
                </p>
                <p>
                Underneath the bright sun, Fracking maintains an air of mystery, identifying as a pusshound. A
                term enigmatic as the man himself, this unique epithet encapsulates the paradoxical essence of
                his existence - blending the ordinary and the extraordinary, the mundane and the magical. Yet,
                what it truly signifies is known to Fracking alone, adding another layer of intrigue to his persona.
                </p>
                <p>
                When night descends, DJ Fracking steps into his realm, crafting sonic symphonies that resonate
                with the heartbeat of the crowd. His performances, legendary in their own right, are known
                far and wide for their grandeur. His entrances, as dramatic and mesmerizing as the music he
                delivers, never fail to cast a spell of anticipation and awe over the audiences.
                </p>
                <p>
                But there is more to DJ Fracking than meets the eye. His polymathic prowess spans an
                astonishing array of fields, from history and art to mathematics and engineering, from chemistry
                to various other scientific disciplines. He dons many hats with ease, embodying the renaissance
                ideal of a universal person, but choosing to identify as a roman aristocrat of the modern day.
                </p>
                <p>
                An entrepreneur by design and a scholar by passion, Fracking imbues each of his ventures with
                a unique touch of his multifaceted personality. The breadth and depth of his knowledge echo in
                his music.
                </p>
                <p>
                And yet, for all his accolades, DJ Fracking remains shrouded in mystery. It’s this combination
                of enigma and talent, secrecy and spectacle, that makes him not just a DJ, but a phenomenon, a
                spirit that flows through the crowds, bringing them what they crave, what they need, and, more
                often than not, what they never knew they wanted.
                </p>
                <p>
                His journey is a testament to the power of music and the potency of mystery. It’s a celebration
                of the unseen, the unheard, and the unexplored. It’s a testament to DJ Fracking, the legend.
                </p>
            </div>
           </div>
        </section>
                
            <section>
                <h2>Contact Me</h2>
                <div className="underline"></div>
                <ContactForm />
            </section>

                </main>
            </div>
            
            <Footer />
        </div>
    );
};

export default LandingPage;
