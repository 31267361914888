import React from "react";
import bonfire from "./assetts/bonfire.png"; // Make sure the path to your image is correct
import { Helmet } from 'react-helmet';

const Frackagedon = () => {
  return (
    <div className="frackagedon-container">
        <Helmet>
                <title>DJFracking</title>
                <meta name="description" content="The Frackagedon" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Frackagedon" />
                <meta property="og:description" content="" />
            </Helmet>
        <div className="bonfire-container">
        <img src={bonfire} alt="Bonfire" className="bonfire-image" />
    </div>
      <h1>Welcome to the Frackagedon</h1>
      <p>Friends, Romans, and Countrymen, you have been invited to the Frackagedon. Please see below for more information.</p>
      
      <h4>Date: May 17-19th</h4>
      <section className="mandatory-items">
        <h2>Mandatory Items</h2>
        <ul>
          <li>Sleeping arangements: Sleeping bag, tent, hammock, or my favorite: air mattress in the car.</li>
          <li>Water</li>
          <li>Rain gear</li>
          <li>Sacrifice - Bring an item to burn.</li>
        </ul>
      </section>
      
      <section className="recommended-items">
        <h2>Recommended Items</h2>
        <ul>
          <li>Food</li>
          <li>Guns and Ammo (don't be a retired)</li>
          <li>Fireworks</li>
          <li>Booze - Wine or Spirits preferred due to cooler space being limited</li>
          <li>Coolers and Ice</li>
          <li>Camping Chair</li>
        </ul>
      </section>
      
      <section className="directions">
        <h2>Directions to the mountain</h2>
        <p>The best way to get to the start of the property is to enter the below coordinates into specifically *Google* Maps. It will take you to the base of the mountain. If you have 4-wheel drive and good clearance, you can take it up the dirt road and to the top.</p>

        <h4>(37.7530808, -80.3681727)</h4>

        <h2>Directions once you get to the gate</h2>
        <p>Enter gate on Pine Ridge Way.</p>
        <p>Continue on Pine Ridge Way at the Greenbriar Vista intersection.</p>
        <p>Continue on Pine Ridge Way at the Lynn Lick Lane intersection.</p>
        <p>Turn left onto Oak Ridge Road at the Oak Ridge intersection.</p>
        <p>Turn left onto Sunrise Drive at the Sunrise Drive intersection.</p>
        <p>Continue on Sunrise Drive at the High Hollow Way intersection.</p>
        <p>Turn left onto Quail Way at the Quail Way intersection.</p>
        <p>Continue on Quail Way until you reach the property.</p>
      </section>
    </div>
  );
};

export default Frackagedon;
